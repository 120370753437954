<script>
import Payer from "@/components/app/invoice/components/Payer.vue";
import {generate} from "@/api/invoice";
import displaySettings from "@/mixin/displaySettings";

export default {
	name: "CreateInvoice",
	components: {Payer},
    mixins: [displaySettings],
	data() {
		return {
			dialogVisible: false,
			discountPercent: null,
			errors: null,
			form: {
				amount: null,
				countContracts: null,
				discount: null,
				payer: {
					type: 1,
					name: null,
					taxNumber: null,
				},
				paymentDestination: null,
			},
		}
	},
	watch: {
		discountPercent(val) {
			this.form.discount = (this.form.amount * val / 100);
		}
	},
	computed: {
		sumAmount() {
			return this.form.amount - this.form.discount;
		},
	},
	methods: {
        validatePercentage() {
            this.blockKeyPress()
            if (this.discountPercent !== null && this.discountPercent.toString().length >= 2) {
                event.preventDefault();
            }
        },
        blockKeyPress() {
            // ASCII codes for '+' and '-'
            const blockedKeys = ['+', '-', 'e'];
            if (blockedKeys.includes(event.key)) {
                event.preventDefault(); // Prevent the character from being added
            }
        },
		handleClose() {
			this.discountPercent = null,
			this.form = {
				amount: null,
				countContracts: null,
				discount: null,
				payer: {
					type: 1,
					name: null,
					taxNumber: null,
				},
				paymentDestination: null,
			};
		},
		generateInvoice() {
			this.errors = null;
			generate(this.form).then(response => {
				if (response?.data) {
                    const file = URL.createObjectURL(response?.data);
                    window.open(file, '__blank')
                    // URL.revokeObjectURL(file)
				}
				this.dialogVisible = false;
				this.handleClose()
			}).catch(async(error) => {
				this.errors = JSON.parse(await error.response.data.text()).data;
			})
		}
	}
}
</script>

<template>
	<div class="create-invoice">
		<div @click="dialogVisible = true" class="cursor-pointer">
			<img src="~@/assets/images/icons/invoice.svg" alt="">
			Згенерувати рахунок
		</div>
		<el-dialog
			:visible.sync="dialogVisible"
			:width="isMobile ? '100%' : '30%'"
			:modal-append-to-body="false"
			:close-on-click-modal="false"
		>
			<div slot="title" class="dialog-title">
				Створення рахунку-фактури
			</div>
			<div class="content">
				<el-row>
					<el-col>
						<label for="amount">Загальний страховий платіж (грн)</label>
						<b-form-input @keypress="blockKeyPress" id="amount" type="number" placeholder="Наприклад, 2159.35" v-model.number="form.amount"></b-form-input>
						<div v-if="errors && errors.amount" class="error">{{ errors.amount[0] }}</div>
					</el-col>
				</el-row>
				<el-row type="flex" :gutter="31" class="mt-4">
					<el-col>
						<label for="discount">Знижка (%)</label>
						<b-form-input
							id="discount"
							placeholder="Необов’язково"
							type="number"
							max="99"
							:controls="false"
                            @keypress="validatePercentage"
							v-model.number="discountPercent"
						></b-form-input>
					</el-col>
					<el-col>
						<label for="count-orders">Кількість договорів</label>
						<b-form-input
							id="count-orders"
							type="number"
							placeholder="Мін 1"
                            @keypress="blockKeyPress"
							v-model="form.countContracts"
						></b-form-input>
						<div v-if="errors && errors.countContracts" class="error">{{ errors.countContracts[0] }}</div>
					</el-col>
				</el-row>
				<div class="payable mt-4">
					<el-row type="flex" align="middle">
						<el-col>
							<span class="title">До сплати</span>
						</el-col>
						<el-col>
							<span class="amount">{{ parseFloat(sumAmount).toFixed(2) }} грн</span>
						</el-col>
					</el-row>
				</div>
				<el-row class="mt-4">
					<el-col>
						<label for="purpose"><b>Призначення платежу</b></label>
						<br>
						<span>
                            За оформлення страхових полісів, ##{{ this.$store.getters.USER.id }},
                        </span>
						<b-form-input id="purpose" placeholder="Наприклад, на АХ9097АХ , № поліса 213273176"
													v-model="form.paymentDestination"></b-form-input>
					</el-col>
				</el-row>
				<el-row>
					<el-col>
						<payer v-model="form.payer" :errors="errors"></payer>
					</el-col>
				</el-row>
				<el-row class="mt-4">
					<el-col>
						<el-button type="primary" @click="generateInvoice" class="w-100">Згенерувати рахунок-фактуру</el-button>
					</el-col>
				</el-row>
				<el-row class="mt-4">
					<el-col>
						<el-button @click="dialogVisible = false" class="cancel w-100">Скасування</el-button>
					</el-col>
				</el-row>
			</div>
		</el-dialog>
	</div>
</template>
<style scoped>
.create-invoice {
	.error {
		font-weight: 400;
		color: #ff0000;
		margin-top: 5px;
        font-size: 10px;
	}
	.dialog-title {
		color: #000000;
		font-weight: 700;
		font-size: 25px;
		text-align: center;
	}

	.el-button--primary {
		background: #009BEB;
        font-size: 16px;
	}

	.cancel {
		border-color: #009BEB;
		color: #009BEB;
        font-size: 16px;
	}

	.el-dialog {
		border-radius: 10px;
	}

	.content {
		color: #24282C;
		font-weight: 700;

        label {
            font-size: 18px;
        }
		span {
			font-weight: 400;
		}

		.payable {
			background: #C9E9F7;
			border-radius: 10px;
			padding-bottom: 18px;
			padding-top: 18px;
			padding-left: 25px;

			.title {
				font-size: 20px;
			}

			.amount {
				font-size: 28px;
			}
		}
	}
}
</style>
