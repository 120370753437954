<script>
import Multiselect from "vue-multiselect";
import {getData} from "@/api/client";
import displaySettings from "@/mixin/displaySettings";

export default {
	name: "SearchClient",
	components: {Multiselect},
    mixins: [displaySettings],
	data() {
		return {
			dialogVisible: false,
			loading: false,
			client: null,
			clients: [],
		}
	},
	methods: {
		handleClose(done) {
			done();
		},
		customClientLabel(client) {
			return client.fio + ' - ' + client.identification_number
		},
		async fetchClients(query) {
			if (!query) return;
			clearTimeout(this.fetchClientsTimeout);
			this.loading = true;
			let params = {
				"requestType": "clientsByUserId",
				"seatchText": query,
				// "customerType":this.getcustomerType()
			};
			this.fetchClientsTimeout = setTimeout(async () => {
				let response = await getData(params);
				this.clients = response.data.data.items;
				this.loading = false;
			}, 1000);
		},
		save() {
			this.$emit('selected-client', {
				name: this.client.fio,
				taxNumber: this.client.identification_number
			})
			this.dialogVisible = false
		}
	}
}
</script>

<template>
	<div class="search-client">
		<el-button @click="dialogVisible=true" size="mini" class="btn">
			<img src="~@/assets/images/insurance/client.svg" alt="">
			<el-tooltip class="item" effect="dark" content="Пошук клієнта">
				<i class="icon question"></i>
			</el-tooltip>
		</el-button>
		<el-dialog
			:visible.sync="dialogVisible"
			:width="isMobile ? '100%' : '50%'"
			:before-close="handleClose"
			:modal-append-to-body="false"
			:modal="false"
		>
			<div class="container m-0 p-0">
				<div class="form-group row">
					<label class="col-lg-6 col-md-6 col-sm-6 col-form-label d-none d-sm-block col-sm-fixed">1. Введіть
						ПІБ або ІПН клієнта</label>
					<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
						<multiselect v-loading="loading"
												 :class="['form-control','custom-search-icon',]"
												 v-model="client"
												 :options="clients"
												 placeholder="ПІБ або ІПН клієнта"
												 @search-change="fetchClients"
												 select-label="Натисніть Enter, щоб вибрати"
												 deselect-label="Натисніть Enter, щоб очистити"
												 selected-label="Обрано"
												 label="fio"
												 track-by="id"
												 :allow-empty="true"
												 :internal-search="false"
												 :searchable="true"
												 :close-on-select="true"
												 :custom-label="customClientLabel"
						>
							<template v-slot:noOptions>
								Почніть вводити ПІБ або ІПН клієнта
							</template>
							<template v-if="!loading" slot="noResult">
								Результатів немає
							</template>
							<template v-if="loading" slot="noResult">
								Зачекайте будь ласка...
							</template>
						</multiselect>
					</div>
				</div>
				<div class="row">
					<div class="col-12">
						<div class="confirm w-100 text-center">
							<button :disabled="!client" :class="[{disabled:!client}]" @click="save">Підтвердити</button>
						</div>
					</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<style scoped>
.search-client {
	.btn {
		padding: 4px 8px;
	}
	.confirm {
		button {
			border-radius: 3px;
			color: #FFFFFF;
			background-color: #FFAD3D;
			border: 0;
			font-size: 1rem;
			cursor: pointer;
			font-weight: var(--font-weight-bold);
			outline: none;
			min-height: 50px;
			padding: 0 15px 0 15px;
			margin-top: 15px;

		}

		button.disabled {
			background-color: #CACACA;
		}
	}
    .question {
        margin-bottom: -7px;
    }
}

</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
