import user from './../authorization_store';

export default {
    state: key => ({
        //TODO найти либу что ли какую в проект, что бы строку в boolean перевести адекватно.
        isHideScore: (localStorage.getItem(user.state.keyUser + '_isHideScore') || false) === 'true',
    }),
    getters: {
        SETTING_HIDE_SCORE: state => {
            return localStorage[user.getters.KEY_USER + '_isHideScore'] || false
        },
    },
    mutations: {
        SET_SETTING_HIDE_SCORE:(state,data) => {
            localStorage.setItem(user.state.keyUser + '_isHideScore', data);
            state.isHideScore = data;
        },
    },
};
