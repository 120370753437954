<script>
import SearchClient from "@/components/app/invoice/components/SearchClient.vue";

export default {
	name: "Payer",
	components: {SearchClient},
	model: {
		prop: 'payer',
		event: 'change',
	},
	props: {
		errors: {
			type: Object,
			default: function () {
				return null
			}
		},
		payer: {
			type: Object,
			default: function () {
				return {
					name: null,
					taxNumber: null,
					type: 1
				}
			}
		}
	},
	methods: {
		setPayer(payer) {
			this.payer.name = payer.name;
			this.payer.taxNumber = payer.taxNumber;
			this.$emit('change', this.payer)
		}
	},
}
</script>

<template>
	<div class="payer">
		<el-row class="mt-4">
			<el-col>
                <div class="title">
                    Тип платника
                </div>
				<div class="radiolist">
					<div class="radioitem">
						<input type="radio" name="payerType" id="person" :checked="true">
						<label for="person" @click="payer.type = 1">Фізична особа</label>
						<div class="check"></div>
					</div>
					<div class="radioitem">
						<input type="radio" name="payerType" id="company" :checked="false">
						<label for="company" @click="payer.type = 2">Юридична особа</label>
						<div class="check"></div>
					</div>
				</div>
			</el-col>
		</el-row>
		<div v-if="payer.type === 1" class="person">
			<el-row class="mt-4">
				<el-col>
					<label for="name" class="w-100">
						<el-row type="flex" align="bottom" justify="space-between">
							<el-col :span="19">ПІБ</el-col>
							<el-col :span="5">
								<search-client @selected-client="setPayer" class="text-right"></search-client>
							</el-col>
						</el-row>
					</label>
					<b-form-input id="name" placeholder="Наприклад, Іванов Іван Іванович" v-model="payer.name"></b-form-input>
					<div class="error" v-if="errors && errors['payer.name']">{{errors['payer.name'][0]}}</div>
				</el-col>
			</el-row>
			<el-row class="mt-4">
				<el-col>
					<label for="tax-number">ІПН</label>
					<b-form-input id="tax-number" v-mask="'##########'" maxlength="10" placeholder="Наприклад, 11111111111"
												v-model="payer.taxNumber"></b-form-input>
					<div class="error" v-if="errors && errors['payer.taxNumber']">{{errors['payer.taxNumber'][0]}}</div>
				</el-col>
			</el-row>
		</div>
		<div v-else class="company">
			<el-row class="mt-4">
				<el-col>
					<label for="name" class="w-100">
						<el-row type="flex" align="bottom" justify="space-between">
							<el-col :span="19">Назва компанії</el-col>
							<el-col :span="5">
								<search-client @selected-client="setPayer" class="text-right"></search-client>
							</el-col>
						</el-row>
					</label>
					<b-form-input id="name" placeholder="Наприклад, ТОВ “АВТО”" v-model="payer.name"></b-form-input>
					<div class="error" v-if="errors && errors['payer.name']">{{errors['payer.name'][0]}}</div>
				</el-col>
			</el-row>
			<el-row class="mt-4">
				<el-col>
					<label for="tax-number">ЄДРПОУ</label>
					<b-form-input id="tax-number" v-mask="'########'" maxlength="8" placeholder="Наприклад, 11111111"
												v-model="payer.taxNumber"></b-form-input>
					<div class="error" v-if="errors && errors['payer.taxNumber']">{{errors['payer.taxNumber'][0]}}</div>
				</el-col>
			</el-row>
		</div>
	</div>
</template>

<style scoped>
.payer {
    .title {
        font-size: 18px;
    }
	color: #24282C;
	font-weight: 700;
	font-size: 14px;
	.error {
		font-weight: 400;
		color: #ff0000;
		margin-top: 5px;
        font-size: 14px;
	}
}
</style>
